import { toDataSourceRequestString, State as GridState } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column  } from "@progress/kendo-react-grid";
import DateTimeCell from "components/DateTimeCell";
import { fetchApi } from "core/fetchApi";
import { useCallback, useEffect, useState } from "react";
import { subMonths } from 'date-fns';
import { Backdrop, CircularProgress, Theme, useTheme } from "@mui/material";
import { Button } from "@progress/kendo-react-buttons";

type ViewModel = {
  OrderServiceExceptionID: number;
  ExcludeFromReporting: boolean;
  FailureReason: number;
  FailureReasonName: string;
  BusinessUnitID: number;
  OrderID: number;
  OrderNumber: number;
  CarrierID: number;
  CarrierName: string;
  StopCount: number;
  PickupDateTime: Date;
  DeliveryDateTime: Date;
  PickupCityName: string;
  PickupCityState: string;
  DeliveryCityName: string;
  DeliveryCityState: string;
  TotalDistance: number;
}

const dataState = {
  skip: 0,
  take: 50,
  sort: [{
    field: "PickupDateTime", dir: "desc"
  }],
  filter: {
    logic: 'and', filters: [
      { field: 'BusinessUnitID', operator: 'eq', value: 2610 },
      { field: 'ExcludeFromReporting', operator: 'eq', value: false },
      { field: 'PickupDateTime', operator: 'gte', value: subMonths(new Date(), 2) },
      { field: 'PickupDateTime', operator: 'lte', value: new Date() }
    ]
  }
} as GridState;

const useStyles = (theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

const ServiceExceptions = () => {

  const theme = useTheme();
  const styles = useStyles(theme);

  const [data, setData] = useState<ViewModel[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchRecords = useCallback(() => {
    setLoading(true);
    fetchApi<{ Data: ViewModel[] }>({ url: `ServiceExceptions?${toDataSourceRequestString(dataState)}`, method: 'POST' })
      .then(({ Data }) => {
        setData(Data);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        alert('Error fetching data.');
      });
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  return <>
    <Backdrop style={styles.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
    <Grid
      data={data}
      size="small"
      scrollable="none"
    >
      <Column field="FailureReasonName" title="Type" />
      <Column field="OrderNumber" title="Order #" />
      <Column field="PickupDateTime" title="Pickup" cell={DateTimeCell} />
      <Column field="PickupCityName" title="Pickup City" cell={(props) => <td>{props.dataItem.PickupCityName}, {props.dataItem.PickupCityState}</td>} />
      <Column field="DeliveryDateTime" title="Delivery" cell={DateTimeCell} />
      <Column field="DeliveryCityName" title="Delivery City" cell={(props) => <td>{props.dataItem.DeliveryCityName}, {props.dataItem.DeliveryCityState}</td>} />
      <Column field="TotalDistance" title="Distance" format="{0:n0} mi" />
      <Column title="Action" cell={(props) => <td className="k-command-cell">
        <Button themeColor="warning" disabled={props.dataItem.FailureReason === 4} onClick={() => window.open(`mailto:CarrierRelations@load1.com?subject=Dispute ${props.dataItem.FailureReasonName} for Order # ${props.dataItem.OrderNumber}`)}>Dispute</Button>
      </td>} />
    </Grid>
  </>
}

export default ServiceExceptions;