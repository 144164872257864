import { GridDetailRowProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import { useEffect, useState } from 'react';
import { fetchApi } from 'core/fetchApi';
import { ApiLink } from 'TypeGen/api-link';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import { formatDateTimeWindow } from 'utils/format';
import { ViewModel } from '..';

type Resonse = {
  VehiclePosition: VehiclePosition;
  Stops: OrderStop[];
  FreightDetails: FreightDetail[];
}

type VehiclePosition = {
  DateTime: string;
  Location: string;
  DegreeBearing: number;
  Coordinates: Coordinate;
}

type OrderStop = {
  OrderStopID: number;
  Event: 1|2;
  StatusName: string;
  LocationName: string;
  AddressLine1: string;
  AddressLine2: string;
  PhoneNumber: string;
  City: string;
  State: string;
  ZipCode: string;
  Miles: number;
  ActiveStop: boolean;
  Coordinates: Coordinate;
  ScheduledDateTime: string;
  LatestStopDateTime: string | null;
  ArrivalDateTime: string | null;
  DepartDateTime: string | null;
  Verb: string;
  Notes: string[];
  Contact: OrderStopContact | null;
  Links: ApiLink[];
}

type FreightDetail = {
  OrderFreightDetailID: number;
  PickupStopID: number;
  DeliveryStopID: number;
  BillOfLadingNumber: string;
  ProofOfDeliverySignedBy: string;
  Pieces: number;
  Weight: number;
  Length: number;
  Width: number;
  Height: number;
  LoadedPieces: number;
  LoadedWeight: number;
  LoadedLength: number;
  LoadedWidth: number;
  LoadedHeight: number;
  Stackable: boolean;
  HazMat: boolean;
  DockHigh: boolean;
}

type Coordinate = {
  Latitude: number;
  Longitude: number;
}

type OrderStopContact = {
  Name: string;
  PhoneNumber: string;
  PhoneNumberExtension: string;
}

const TrackDetailRow = (props: GridDetailRowProps) => {
    const { TripID } = props.dataItem as ViewModel;
    const [data, setData] = useState<Resonse>();

    useEffect(() => {
      fetchApi<Resonse>({ url: `Trip/${TripID}` })
        .then((response) => {
          setData(response);
        });
    }, [TripID]);

    const googleMaps = (query: string) => {
      window.open(`https://www.google.com/maps/search/?api=1&query=${query}`, '_blank', 'noreferrer');
    }

    if (!data) return null;

    return <div>
      <TableContainer component={Paper} className="k-my-2">
        <Table sx={{ minWidth: 650}}>
          <TableHead>
            <TableRow>
              <TableCell>Stop</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Scheduled</TableCell>
              <TableCell align="right">Miles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.Stops.map((stop) => {
              const address = `${stop.AddressLine1} ${stop.AddressLine2} ${stop.City}, ${stop.State} ${stop.ZipCode}`;
              return <TableRow key={stop.OrderStopID} selected={stop.ActiveStop}>
                <TableCell>{stop.Event === 1 ? 'Pickup' : 'Delivery'} {stop.StatusName}</TableCell>
                <TableCell>{stop.LocationName}</TableCell>
                <TableCell>
                  {address}
                  <IconButton color="primary" aria-label="Map" onClick={() => googleMaps(address)}>
                    <MapIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{formatDateTimeWindow(stop.ScheduledDateTime, stop.LatestStopDateTime)} {stop.Verb}</TableCell>
                <TableCell style={{ textAlign: 'right', padding: 16 }}>{formatNumber(stop.Miles, 'n0')}</TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} className="k-my-2">
          <Table sx={{ minWidth: 650}}>
            <TableHead>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell align='right'>Pieces</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Dims (LxWxH)</TableCell>
                <TableCell>Stackable</TableCell>
                <TableCell>Dock High</TableCell>
                <TableCell>HazMat</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.FreightDetails.map((freight) => {
                return (
                  <TableRow
                    key={freight.OrderFreightDetailID}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{data.Stops.find(s => s.OrderStopID === freight.PickupStopID)?.LocationName}</TableCell>
                    <TableCell>{data.Stops.find(s => s.OrderStopID === freight.DeliveryStopID)?.LocationName}</TableCell>
                    <TableCell component="th" scope="row" align='right'>{formatNumber(freight.Pieces, 'n0')}</TableCell>
                    <TableCell>{formatNumber(freight.Weight, 'n0')} lbs</TableCell>
                    <TableCell>{freight.Length} x {freight.Width} x {freight.Height} in</TableCell>
                    <TableCell>{freight.Stackable ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{freight.DockHigh ? 'Required' : 'No'}</TableCell>
                    <TableCell style={{ color: freight.HazMat ? 'red' : undefined }}>{freight.HazMat ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
      </TableContainer>
    </div>;
}

export default TrackDetailRow;
